/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getManual = /* GraphQL */ `
  query GetManual($id: ID!) {
    getManual(id: $id) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listManuals = /* GraphQL */ `
  query ListManuals(
    $filter: ModelManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncManuals = /* GraphQL */ `
  query SyncManuals(
    $filter: ModelManualFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncManuals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($id: ID!) {
    getInspection(id: $id) {
      id
      name
      type
      date
      setTableName
      responsible
      customerWitness
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbineInspections {
        items {
          id
          name
          setTableName
          inspectionID
          manualID
          windTurbineID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspections = /* GraphQL */ `
  query SyncInspections(
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTurbineInspection = /* GraphQL */ `
  query GetTurbineInspection($id: ID!) {
    getTurbineInspection(id: $id) {
      id
      name
      setTableName
      inspectionID
      inspection {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manualID
      manual {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      windTurbineID
      windTurbine {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        windFarmID
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inspectionResults {
        items {
          id
          turbineInspectionID
          windTurbineID
          itemId
          result
          obs
          childrenResults
          childrenObs
          customResults
          changeObs
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          turbineInspectionID
          modelNumber
          modelItemName
          type
          s3Path
          caption
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTurbineInspections = /* GraphQL */ `
  query ListTurbineInspections(
    $filter: ModelTurbineInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTurbineInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        setTableName
        inspectionID
        inspection {
          id
          name
          type
          date
          setTableName
          responsible
          customerWitness
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manualID
        manual {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        windTurbineID
        windTurbine {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inspectionResults {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTurbineInspections = /* GraphQL */ `
  query SyncTurbineInspections(
    $filter: ModelTurbineInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTurbineInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        setTableName
        inspectionID
        inspection {
          id
          name
          type
          date
          setTableName
          responsible
          customerWitness
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manualID
        manual {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        windTurbineID
        windTurbine {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inspectionResults {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionResult = /* GraphQL */ `
  query GetInspectionResult($id: ID!) {
    getInspectionResult(id: $id) {
      id
      turbineInspectionID
      windTurbineID
      itemId
      result
      obs
      childrenResults
      childrenObs
      customResults
      changeObs
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspectionResults = /* GraphQL */ `
  query ListInspectionResults(
    $filter: ModelInspectionResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        turbineInspectionID
        windTurbineID
        itemId
        result
        obs
        childrenResults
        childrenObs
        customResults
        changeObs
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionResults = /* GraphQL */ `
  query SyncInspectionResults(
    $filter: ModelInspectionResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        turbineInspectionID
        windTurbineID
        itemId
        result
        obs
        childrenResults
        childrenObs
        customResults
        changeObs
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      turbineInspectionID
      modelNumber
      modelItemName
      type
      s3Path
      caption
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        turbineInspectionID
        modelNumber
        modelItemName
        type
        s3Path
        caption
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPhotos = /* GraphQL */ `
  query SyncPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        turbineInspectionID
        modelNumber
        modelItemName
        type
        s3Path
        caption
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTechnician = /* GraphQL */ `
  query GetTechnician($id: ID!) {
    getTechnician(id: $id) {
      id
      name
      company
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTechnicians = /* GraphQL */ `
  query ListTechnicians(
    $filter: ModelTechnicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTechnicians(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        company
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTechnicians = /* GraphQL */ `
  query SyncTechnicians(
    $filter: ModelTechnicianFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTechnicians(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        company
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWindFarm = /* GraphQL */ `
  query GetWindFarm($id: ID!) {
    getWindFarm(id: $id) {
      id
      name
      owner
      windTurbines {
        items {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWindFarms = /* GraphQL */ `
  query ListWindFarms(
    $filter: ModelWindFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWindFarms = /* GraphQL */ `
  query SyncWindFarms(
    $filter: ModelWindFarmFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWindFarms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWindTurbine = /* GraphQL */ `
  query GetWindTurbine($id: ID!) {
    getWindTurbine(id: $id) {
      id
      name
      tower
      brand
      model
      setTableName
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWindTurbines = /* GraphQL */ `
  query ListWindTurbines(
    $filter: ModelWindTurbineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWindTurbines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWindTurbines = /* GraphQL */ `
  query SyncWindTurbines(
    $filter: ModelWindTurbineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWindTurbines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionModel = /* GraphQL */ `
  query GetInspectionModel($id: ID!) {
    getInspectionModel(id: $id) {
      id
      modelNumber
      name
      inspectionModelItems {
        items {
          id
          inspectionModelID
          modelNumber
          itemModelNumber
          name
          description
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspectionModels = /* GraphQL */ `
  query ListInspectionModels(
    $filter: ModelInspectionModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelNumber
        name
        inspectionModelItems {
          items {
            id
            inspectionModelID
            modelNumber
            itemModelNumber
            name
            description
            isSpecialReport
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionModels = /* GraphQL */ `
  query SyncInspectionModels(
    $filter: ModelInspectionModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        modelNumber
        name
        inspectionModelItems {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionModelItem = /* GraphQL */ `
  query GetInspectionModelItem($id: ID!) {
    getInspectionModelItem(id: $id) {
      id
      inspectionModelID
      modelNumber
      itemModelNumber
      name
      description
      inspectionModelItemChildren {
        items {
          id
          inspectionModelItemID
          modelNumber
          itemModelNumber
          itemChildNumber
          name
          title
          unit
          judgement
          resultType
          isHalfYear
          isOneYear
          isPeriodicInspection
          isBusinessInspection
          isInternalUse
          hasPhoto
          rowSpan
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspectionModelItems = /* GraphQL */ `
  query ListInspectionModelItems(
    $filter: ModelInspectionModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionModelItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inspectionModelID
        modelNumber
        itemModelNumber
        name
        description
        inspectionModelItemChildren {
          items {
            id
            inspectionModelItemID
            modelNumber
            itemModelNumber
            itemChildNumber
            name
            title
            unit
            judgement
            resultType
            isHalfYear
            isOneYear
            isPeriodicInspection
            isBusinessInspection
            isInternalUse
            hasPhoto
            rowSpan
            author
            createdAt
            isSpecialReport
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isSpecialReport
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionModelItems = /* GraphQL */ `
  query SyncInspectionModelItems(
    $filter: ModelInspectionModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionModelItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionModelID
        modelNumber
        itemModelNumber
        name
        description
        inspectionModelItemChildren {
          nextToken
          startedAt
        }
        isSpecialReport
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionModelItemChild = /* GraphQL */ `
  query GetInspectionModelItemChild($id: ID!) {
    getInspectionModelItemChild(id: $id) {
      id
      inspectionModelItemID
      modelNumber
      itemModelNumber
      itemChildNumber
      name
      title
      unit
      judgement
      resultType
      isHalfYear
      isOneYear
      isPeriodicInspection
      isBusinessInspection
      isInternalUse
      hasPhoto
      rowSpan
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInspectionModelItemChildren = /* GraphQL */ `
  query ListInspectionModelItemChildren(
    $filter: ModelInspectionModelItemChildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionModelItemChildren(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inspectionModelItemID
        modelNumber
        itemModelNumber
        itemChildNumber
        name
        title
        unit
        judgement
        resultType
        isHalfYear
        isOneYear
        isPeriodicInspection
        isBusinessInspection
        isInternalUse
        hasPhoto
        rowSpan
        isSpecialReport
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionModelItemChildren = /* GraphQL */ `
  query SyncInspectionModelItemChildren(
    $filter: ModelInspectionModelItemChildFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionModelItemChildren(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionModelItemID
        modelNumber
        itemModelNumber
        itemChildNumber
        name
        title
        unit
        judgement
        resultType
        isHalfYear
        isOneYear
        isPeriodicInspection
        isBusinessInspection
        isInternalUse
        hasPhoto
        rowSpan
        isSpecialReport
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemConfiguration = /* GraphQL */ `
  query GetSystemConfiguration($id: ID!) {
    getSystemConfiguration(id: $id) {
      id
      name
      systemVersion
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSystemConfigurations = /* GraphQL */ `
  query ListSystemConfigurations(
    $filter: ModelSystemConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        systemVersion
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemConfigurations = /* GraphQL */ `
  query SyncSystemConfigurations(
    $filter: ModelSystemConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        systemVersion
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBoltInspection = /* GraphQL */ `
  query GetBoltInspection($id: ID!) {
    getBoltInspection(id: $id) {
      id
      boltID
      bolt {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      date
      inspectionModelItemId
      itemId
      startNumber
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBoltInspections = /* GraphQL */ `
  query ListBoltInspections(
    $filter: ModelBoltInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoltInspections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boltID
        bolt {
          id
          windTurbineID
          number
          quantity
          torque
          confirmation
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        inspectionModelItemId
        itemId
        startNumber
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBoltInspections = /* GraphQL */ `
  query SyncBoltInspections(
    $filter: ModelBoltInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBoltInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        boltID
        bolt {
          id
          windTurbineID
          number
          quantity
          torque
          confirmation
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        inspectionModelItemId
        itemId
        startNumber
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBolt = /* GraphQL */ `
  query GetBolt($id: ID!) {
    getBolt(id: $id) {
      id
      windTurbineID
      number
      quantity
      torque
      confirmation
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBolts = /* GraphQL */ `
  query ListBolts(
    $filter: ModelBoltFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBolts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBolts = /* GraphQL */ `
  query SyncBolts(
    $filter: ModelBoltFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBolts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCarryOnTool = /* GraphQL */ `
  query GetCarryOnTool($id: ID!) {
    getCarryOnTool(id: $id) {
      id
      turbineInspectionID
      dateStart
      name
      quantity
      dateEnd
      technician
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCarryOnTools = /* GraphQL */ `
  query ListCarryOnTools(
    $filter: ModelCarryOnToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarryOnTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        turbineInspectionID
        dateStart
        name
        quantity
        dateEnd
        technician
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarryOnTools = /* GraphQL */ `
  query SyncCarryOnTools(
    $filter: ModelCarryOnToolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarryOnTools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        turbineInspectionID
        dateStart
        name
        quantity
        dateEnd
        technician
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsedTools = /* GraphQL */ `
  query GetUsedTools($id: ID!) {
    getUsedTools(id: $id) {
      id
      inspectionID
      calibrationID
      calibration {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbines
      dateStart
      dateEnd
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsedTools = /* GraphQL */ `
  query ListUsedTools(
    $filter: ModelUsedToolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsedTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inspectionID
        calibrationID
        calibration {
          id
          managementNumber
          toolID
          tool {
            id
            name
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          makerID
          maker {
            id
            name
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          modelNumber
          serialNumber
          expirationDate
          expirationObs
          certificateS3Path
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbines
        dateStart
        dateEnd
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsedTools = /* GraphQL */ `
  query SyncUsedTools(
    $filter: ModelUsedToolsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsedTools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionID
        calibrationID
        calibration {
          id
          managementNumber
          toolID
          makerID
          modelNumber
          serialNumber
          expirationDate
          expirationObs
          certificateS3Path
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbines
        dateStart
        dateEnd
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCalibration = /* GraphQL */ `
  query GetCalibration($id: ID!) {
    getCalibration(id: $id) {
      id
      managementNumber
      toolID
      tool {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      makerID
      maker {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      modelNumber
      serialNumber
      expirationDate
      expirationObs
      certificateS3Path
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCalibrations = /* GraphQL */ `
  query ListCalibrations(
    $filter: ModelCalibrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalibrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCalibrations = /* GraphQL */ `
  query SyncCalibrations(
    $filter: ModelCalibrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCalibrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTool = /* GraphQL */ `
  query GetTool($id: ID!) {
    getTool(id: $id) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTools = /* GraphQL */ `
  query ListTools(
    $filter: ModelToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTools = /* GraphQL */ `
  query SyncTools(
    $filter: ModelToolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMaker = /* GraphQL */ `
  query GetMaker($id: ID!) {
    getMaker(id: $id) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMakers = /* GraphQL */ `
  query ListMakers(
    $filter: ModelMakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMakers = /* GraphQL */ `
  query SyncMakers(
    $filter: ModelMakerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMakers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listByName = /* GraphQL */ `
  query ListByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBySetInspection = /* GraphQL */ `
  query ListBySetInspection(
    $setTableName: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySetInspection(
      setTableName: $setTableName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBySetTurbineInspection = /* GraphQL */ `
  query ListBySetTurbineInspection(
    $setTableName: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTurbineInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySetTurbineInspection(
      setTableName: $setTableName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        setTableName
        inspectionID
        inspection {
          id
          name
          type
          date
          setTableName
          responsible
          customerWitness
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        manualID
        manual {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        windTurbineID
        windTurbine {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inspectionResults {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listByItemId = /* GraphQL */ `
  query ListByItemId(
    $itemId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByItemId(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        turbineInspectionID
        windTurbineID
        itemId
        result
        obs
        childrenResults
        childrenObs
        customResults
        changeObs
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBySetWindTurbine = /* GraphQL */ `
  query ListBySetWindTurbine(
    $setTableName: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWindTurbineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySetWindTurbine(
      setTableName: $setTableName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const boltInspectionByItemId = /* GraphQL */ `
  query BoltInspectionByItemId(
    $itemId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoltInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    boltInspectionByItemId(
      itemId: $itemId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        boltID
        bolt {
          id
          windTurbineID
          number
          quantity
          torque
          confirmation
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        inspectionModelItemId
        itemId
        startNumber
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
