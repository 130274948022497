import { Flex, Image, useTheme } from "@aws-amplify/ui-react";

/**
 * Header
 * @returns
 */
export default function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        alt="logo"
        src="./img/jts.svg"
        padding={tokens.space.medium}
        width={200}
      />
    </Flex>
  );
}
