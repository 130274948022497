import { Heading, useTheme } from "@aws-amplify/ui-react";

/**
 * SignInHeader
 * @returns
 */
export default function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading padding={`${tokens.space.xl} 0 0 0`} level={3}>
      サインイン
    </Heading>
  );
}
