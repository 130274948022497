import { API, graphqlOperation } from "aws-amplify";
import {
  listBySetInspection,
  listBySetTurbineInspection,
  listInspectionResults,
  listInspectionModels,
  listBoltInspections,
  listInspectionModelItems,
  listPhotos,
  listTechnicians,
  listWindTurbines,
  listWindFarms,
  listSystemConfigurations,
  listMakers,
  listTools,
  listCalibrations,
  listUsedTools
} from "src/graphql/queries";

const LIMIT = 1000;

/**
 * queryInspections
 * @param {*} setNextToken
 * @param {*} setInspections
 * @param {*} token
 * @param {*} list
 */
export const queryInspections = async (
  setNextToken,
  setInspections,
  token,
  list
) => {
  try {
    const queryData = await API.graphql(
      graphqlOperation(listBySetInspection, {
        limit: 10,
        setTableName: "Inspection",
        sortDirection: "DESC",
        nextToken: token,
      })
    );

    const queryNextToken = queryData.data.listBySetInspection.nextToken;
    const resultQueryData = queryData.data.listBySetInspection.items;
    const newQueryData = list.concat(resultQueryData);

    setNextToken(queryNextToken);
    setInspections(newQueryData);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryInspectionResults
 * @param {*} filter
 * @param {*} token
 * @param {*} list
 * @param {*} setData
 */
export const queryInspectionResults = async (filter, setData) => {
  try {
    let finalList = [];

    const listData = await API.graphql(
      graphqlOperation(listInspectionResults, {
        filter: filter,
        limit: LIMIT,
      })
    );

    let nextToken = listData.data.listInspectionResults.nextToken;
    finalList = finalList.concat(listData.data.listInspectionResults.items);

    while (nextToken) {
      const loopListData = await API.graphql(
        graphqlOperation(listInspectionResults, {
          filter: filter,
          limit: LIMIT,
          nextToken: nextToken,
        })
      );

      nextToken = loopListData.data.listInspectionResults.nextToken;
      finalList = finalList.concat(
        loopListData.data.listInspectionResults.items
      );
    }

    setData(
      finalList.sort((a, b) =>
        a["itemId"].localeCompare(b["itemId"], undefined, {
          numeric: true,
          sensitivity: "base",
        })
      )
    );
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryTurbineInspections
 * @param {*} filter
 * @param {*} token
 * @param {*} list
 * @param {*} setData
 */
export const queryTurbineInspections = async (filter, token, list, setData) => {
  try {
    const queryData = await API.graphql(
      graphqlOperation(listBySetTurbineInspection, {
        setTableName: "TurbineInspection",
        sortDirection: "ASC",
        filter: filter,
        limit: LIMIT,
        nextToken: token,
      })
    );

    const queryNextToken = queryData.data.listBySetTurbineInspection.nextToken;
    const turbineInspectionData =
      queryData.data.listBySetTurbineInspection.items;
    const newTurbineInspectionData = list.concat(turbineInspectionData);

    setData(newTurbineInspectionData);

    if (queryNextToken) {
      queryTurbineInspections(
        filter,
        queryNextToken,
        newTurbineInspectionData,
        setData
      );
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryPhotos
 * @param {*} filter
 * @param {*} token
 * @param {*} list
 * @param {*} setData
 */
export const queryPhotos = async (filter, token, list, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listPhotos, {
        filter: filter,
        limit: LIMIT,
        nextToken: token,
      })
    );
    const queryNextToken = listData.data.listPhotos.nextToken;
    const resultQueryData = listData.data.listPhotos.items;
    const newQueryData = list.concat(resultQueryData);
    if (queryNextToken) {
      queryPhotos(filter, queryNextToken, newQueryData, setData);
    } else {
      setData(
        newQueryData.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      );
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryInspectionModels
 * @param {*} filter
 * @param {*} setData
 */
export const queryInspectionModels = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listInspectionModels, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const modelItems = listData.data.listInspectionModels.items.sort((a, b) =>
      a.modelNumber > b.modelNumber ? 1 : -1
    );
    setData(modelItems);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryBoltInspection
 * @param {*} filter
 * @param {*} setData
 */
export const queryBoltInspection = async (filter, token, list, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listBoltInspections, {
        filter: filter,
        limit: LIMIT,
        nextToken: token,
      })
    );

    const queryNextToken = listData.data.listBoltInspections.nextToken;
    const resultQueryData = listData.data.listBoltInspections.items;
    const newQueryData = list.concat(resultQueryData);

    if (queryNextToken) {
      queryBoltInspection(filter, queryNextToken, newQueryData, setData);
    } else {
      setData(newQueryData?.[0]);
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryInspectionModelItems
 * @param {*} filter
 * @param {*} setData
 */
export const queryInspectionModelItems = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listInspectionModelItems, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const modelItems = listData.data.listInspectionModelItems.items;
    setData(modelItems);
  } catch (err) {
    console.log(err);
  }
};

/**
 * querySystemConfigurations
 * @param {*} filter
 * @param {*} setData
 */
export const querySystemConfigurations = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listSystemConfigurations, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const systemConfiguration = listData.data.listSystemConfigurations.items[0];
    setData(systemConfiguration);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryTechnicians
 * @param {*} filter
 * @param {*} setData
 */
export const queryTechnicians = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listTechnicians, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataTechnicians = listData.data.listTechnicians.items;
    const activeData = dataTechnicians.filter((obj) => obj._deleted !== true);
    setData(activeData);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryMakers
 * @param {*} filter
 * @param {*} setData
 */
export const queryMakers = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listMakers, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataMakers = listData.data.listMakers.items;
    const activeData = dataMakers.filter((obj) => obj._deleted !== true);
    setData(activeData);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryCalibration
 * @param {*} filter
 * @param {*} setData
 */
export const queryCalibrations = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listCalibrations, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataCalibrations = listData.data.listCalibrations.items;
    const activeData = dataCalibrations.filter((obj) => obj._deleted !== true);
    setData(activeData);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryTools
 * @param {*} filter
 * @param {*} setData
 */
export const queryTools = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listTools, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataTools = listData.data.listTools.items;
    const activeData = dataTools.filter((obj) => obj._deleted !== true);
    setData(activeData);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryWindFarms
 * @param {*} filter
 * @param {*} setData
 */
export const queryWindFarms = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listWindFarms, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataWindFarms = listData.data.listWindFarms.items;
    setData(dataWindFarms);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryWindTurbines
 * @param {*} filter
 * @param {*} setData
 */
export const queryWindTurbines = async (filter, setData) => {
  try {
    const listData = await API.graphql(
      graphqlOperation(listWindTurbines, {
        filter: filter,
        limit: LIMIT,
      })
    );
    const dataWindTurbines = listData.data.listWindTurbines.items;
    setData(dataWindTurbines);
  } catch (err) {
    console.log(err);
  }
};

/**
 * queryUsedTools
 * @param {*} filter
 * @param {*} token
 * @param {*} list
 * @param {*} setData
 */
export const queryUsedTools = async (filter, setData) => {
  try {
    let finalList = [];

    const listData = await API.graphql(
      graphqlOperation(listUsedTools, {
        filter: filter,
        limit: LIMIT,
      })
    );

    let nextToken = listData.data.listUsedTools.nextToken;
    finalList = finalList.concat(listData.data.listUsedTools.items);

    while (nextToken) {
      const loopListData = await API.graphql(
        graphqlOperation(listUsedTools, {
          filter: filter,
          limit: LIMIT,
          nextToken: nextToken,
        })
      );

      nextToken = loopListData.data.listUsedTools.nextToken;
      finalList = finalList.concat(loopListData.data.listUsedTools.items);
    }

    setData(finalList);
  } catch (err) {
    console.log(err);
  }
};