import { Flex, Text, useTheme } from "@aws-amplify/ui-react";

/**
 * Footer
 * @returns
 */
export default function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; {new Date().getFullYear()} 有限会社イー・ウィンド. All rights reserved.</Text>
    </Flex>
  );
}
