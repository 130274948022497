/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createManual = /* GraphQL */ `
  mutation CreateManual(
    $input: CreateManualInput!
    $condition: ModelManualConditionInput
  ) {
    createManual(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateManual = /* GraphQL */ `
  mutation UpdateManual(
    $input: UpdateManualInput!
    $condition: ModelManualConditionInput
  ) {
    updateManual(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteManual = /* GraphQL */ `
  mutation DeleteManual(
    $input: DeleteManualInput!
    $condition: ModelManualConditionInput
  ) {
    deleteManual(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      id
      name
      type
      date
      setTableName
      responsible
      customerWitness
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbineInspections {
        items {
          id
          name
          setTableName
          inspectionID
          manualID
          windTurbineID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      id
      name
      type
      date
      setTableName
      responsible
      customerWitness
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbineInspections {
        items {
          id
          name
          setTableName
          inspectionID
          manualID
          windTurbineID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      id
      name
      type
      date
      setTableName
      responsible
      customerWitness
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbineInspections {
        items {
          id
          name
          setTableName
          inspectionID
          manualID
          windTurbineID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTurbineInspection = /* GraphQL */ `
  mutation CreateTurbineInspection(
    $input: CreateTurbineInspectionInput!
    $condition: ModelTurbineInspectionConditionInput
  ) {
    createTurbineInspection(input: $input, condition: $condition) {
      id
      name
      setTableName
      inspectionID
      inspection {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manualID
      manual {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      windTurbineID
      windTurbine {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inspectionResults {
        items {
          id
          turbineInspectionID
          windTurbineID
          itemId
          result
          obs
          childrenResults
          childrenObs
          customResults
          changeObs
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          turbineInspectionID
          modelNumber
          modelItemName
          type
          s3Path
          caption
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTurbineInspection = /* GraphQL */ `
  mutation UpdateTurbineInspection(
    $input: UpdateTurbineInspectionInput!
    $condition: ModelTurbineInspectionConditionInput
  ) {
    updateTurbineInspection(input: $input, condition: $condition) {
      id
      name
      setTableName
      inspectionID
      inspection {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manualID
      manual {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      windTurbineID
      windTurbine {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inspectionResults {
        items {
          id
          turbineInspectionID
          windTurbineID
          itemId
          result
          obs
          childrenResults
          childrenObs
          customResults
          changeObs
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          turbineInspectionID
          modelNumber
          modelItemName
          type
          s3Path
          caption
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTurbineInspection = /* GraphQL */ `
  mutation DeleteTurbineInspection(
    $input: DeleteTurbineInspectionInput!
    $condition: ModelTurbineInspectionConditionInput
  ) {
    deleteTurbineInspection(input: $input, condition: $condition) {
      id
      name
      setTableName
      inspectionID
      inspection {
        id
        name
        type
        date
        setTableName
        responsible
        customerWitness
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        turbineInspections {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      manualID
      manual {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      windTurbineID
      windTurbine {
        id
        name
        tower
        brand
        model
        setTableName
        windFarmID
        windFarm {
          id
          name
          owner
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inspectionResults {
        items {
          id
          turbineInspectionID
          windTurbineID
          itemId
          result
          obs
          childrenResults
          childrenObs
          customResults
          changeObs
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          turbineInspectionID
          modelNumber
          modelItemName
          type
          s3Path
          caption
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspectionResult = /* GraphQL */ `
  mutation CreateInspectionResult(
    $input: CreateInspectionResultInput!
    $condition: ModelInspectionResultConditionInput
  ) {
    createInspectionResult(input: $input, condition: $condition) {
      id
      turbineInspectionID
      windTurbineID
      itemId
      result
      obs
      childrenResults
      childrenObs
      customResults
      changeObs
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspectionResult = /* GraphQL */ `
  mutation UpdateInspectionResult(
    $input: UpdateInspectionResultInput!
    $condition: ModelInspectionResultConditionInput
  ) {
    updateInspectionResult(input: $input, condition: $condition) {
      id
      turbineInspectionID
      windTurbineID
      itemId
      result
      obs
      childrenResults
      childrenObs
      customResults
      changeObs
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspectionResult = /* GraphQL */ `
  mutation DeleteInspectionResult(
    $input: DeleteInspectionResultInput!
    $condition: ModelInspectionResultConditionInput
  ) {
    deleteInspectionResult(input: $input, condition: $condition) {
      id
      turbineInspectionID
      windTurbineID
      itemId
      result
      obs
      childrenResults
      childrenObs
      customResults
      changeObs
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      turbineInspectionID
      modelNumber
      modelItemName
      type
      s3Path
      caption
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      turbineInspectionID
      modelNumber
      modelItemName
      type
      s3Path
      caption
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      turbineInspectionID
      modelNumber
      modelItemName
      type
      s3Path
      caption
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTechnician = /* GraphQL */ `
  mutation CreateTechnician(
    $input: CreateTechnicianInput!
    $condition: ModelTechnicianConditionInput
  ) {
    createTechnician(input: $input, condition: $condition) {
      id
      name
      company
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTechnician = /* GraphQL */ `
  mutation UpdateTechnician(
    $input: UpdateTechnicianInput!
    $condition: ModelTechnicianConditionInput
  ) {
    updateTechnician(input: $input, condition: $condition) {
      id
      name
      company
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTechnician = /* GraphQL */ `
  mutation DeleteTechnician(
    $input: DeleteTechnicianInput!
    $condition: ModelTechnicianConditionInput
  ) {
    deleteTechnician(input: $input, condition: $condition) {
      id
      name
      company
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWindFarm = /* GraphQL */ `
  mutation CreateWindFarm(
    $input: CreateWindFarmInput!
    $condition: ModelWindFarmConditionInput
  ) {
    createWindFarm(input: $input, condition: $condition) {
      id
      name
      owner
      windTurbines {
        items {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWindFarm = /* GraphQL */ `
  mutation UpdateWindFarm(
    $input: UpdateWindFarmInput!
    $condition: ModelWindFarmConditionInput
  ) {
    updateWindFarm(input: $input, condition: $condition) {
      id
      name
      owner
      windTurbines {
        items {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWindFarm = /* GraphQL */ `
  mutation DeleteWindFarm(
    $input: DeleteWindFarmInput!
    $condition: ModelWindFarmConditionInput
  ) {
    deleteWindFarm(input: $input, condition: $condition) {
      id
      name
      owner
      windTurbines {
        items {
          id
          name
          tower
          brand
          model
          setTableName
          windFarmID
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWindTurbine = /* GraphQL */ `
  mutation CreateWindTurbine(
    $input: CreateWindTurbineInput!
    $condition: ModelWindTurbineConditionInput
  ) {
    createWindTurbine(input: $input, condition: $condition) {
      id
      name
      tower
      brand
      model
      setTableName
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWindTurbine = /* GraphQL */ `
  mutation UpdateWindTurbine(
    $input: UpdateWindTurbineInput!
    $condition: ModelWindTurbineConditionInput
  ) {
    updateWindTurbine(input: $input, condition: $condition) {
      id
      name
      tower
      brand
      model
      setTableName
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWindTurbine = /* GraphQL */ `
  mutation DeleteWindTurbine(
    $input: DeleteWindTurbineInput!
    $condition: ModelWindTurbineConditionInput
  ) {
    deleteWindTurbine(input: $input, condition: $condition) {
      id
      name
      tower
      brand
      model
      setTableName
      windFarmID
      windFarm {
        id
        name
        owner
        windTurbines {
          nextToken
          startedAt
        }
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspectionModel = /* GraphQL */ `
  mutation CreateInspectionModel(
    $input: CreateInspectionModelInput!
    $condition: ModelInspectionModelConditionInput
  ) {
    createInspectionModel(input: $input, condition: $condition) {
      id
      modelNumber
      name
      inspectionModelItems {
        items {
          id
          inspectionModelID
          modelNumber
          itemModelNumber
          name
          description
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspectionModel = /* GraphQL */ `
  mutation UpdateInspectionModel(
    $input: UpdateInspectionModelInput!
    $condition: ModelInspectionModelConditionInput
  ) {
    updateInspectionModel(input: $input, condition: $condition) {
      id
      modelNumber
      name
      inspectionModelItems {
        items {
          id
          inspectionModelID
          modelNumber
          itemModelNumber
          name
          description
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspectionModel = /* GraphQL */ `
  mutation DeleteInspectionModel(
    $input: DeleteInspectionModelInput!
    $condition: ModelInspectionModelConditionInput
  ) {
    deleteInspectionModel(input: $input, condition: $condition) {
      id
      modelNumber
      name
      inspectionModelItems {
        items {
          id
          inspectionModelID
          modelNumber
          itemModelNumber
          name
          description
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspectionModelItem = /* GraphQL */ `
  mutation CreateInspectionModelItem(
    $input: CreateInspectionModelItemInput!
    $condition: ModelInspectionModelItemConditionInput
  ) {
    createInspectionModelItem(input: $input, condition: $condition) {
      id
      inspectionModelID
      modelNumber
      itemModelNumber
      name
      description
      inspectionModelItemChildren {
        items {
          id
          inspectionModelItemID
          modelNumber
          itemModelNumber
          itemChildNumber
          name
          title
          unit
          judgement
          resultType
          isHalfYear
          isOneYear
          isPeriodicInspection
          isBusinessInspection
          isInternalUse
          hasPhoto
          rowSpan
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspectionModelItem = /* GraphQL */ `
  mutation UpdateInspectionModelItem(
    $input: UpdateInspectionModelItemInput!
    $condition: ModelInspectionModelItemConditionInput
  ) {
    updateInspectionModelItem(input: $input, condition: $condition) {
      id
      inspectionModelID
      modelNumber
      itemModelNumber
      name
      description
      inspectionModelItemChildren {
        items {
          id
          inspectionModelItemID
          modelNumber
          itemModelNumber
          itemChildNumber
          name
          title
          unit
          judgement
          resultType
          isHalfYear
          isOneYear
          isPeriodicInspection
          isBusinessInspection
          isInternalUse
          hasPhoto
          rowSpan
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspectionModelItem = /* GraphQL */ `
  mutation DeleteInspectionModelItem(
    $input: DeleteInspectionModelItemInput!
    $condition: ModelInspectionModelItemConditionInput
  ) {
    deleteInspectionModelItem(input: $input, condition: $condition) {
      id
      inspectionModelID
      modelNumber
      itemModelNumber
      name
      description
      inspectionModelItemChildren {
        items {
          id
          inspectionModelItemID
          modelNumber
          itemModelNumber
          itemChildNumber
          name
          title
          unit
          judgement
          resultType
          isHalfYear
          isOneYear
          isPeriodicInspection
          isBusinessInspection
          isInternalUse
          hasPhoto
          rowSpan
          isSpecialReport
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInspectionModelItemChild = /* GraphQL */ `
  mutation CreateInspectionModelItemChild(
    $input: CreateInspectionModelItemChildInput!
    $condition: ModelInspectionModelItemChildConditionInput
  ) {
    createInspectionModelItemChild(input: $input, condition: $condition) {
      id
      inspectionModelItemID
      modelNumber
      itemModelNumber
      itemChildNumber
      name
      title
      unit
      judgement
      resultType
      isHalfYear
      isOneYear
      isPeriodicInspection
      isBusinessInspection
      isInternalUse
      hasPhoto
      rowSpan
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInspectionModelItemChild = /* GraphQL */ `
  mutation UpdateInspectionModelItemChild(
    $input: UpdateInspectionModelItemChildInput!
    $condition: ModelInspectionModelItemChildConditionInput
  ) {
    updateInspectionModelItemChild(input: $input, condition: $condition) {
      id
      inspectionModelItemID
      modelNumber
      itemModelNumber
      itemChildNumber
      name
      title
      unit
      judgement
      resultType
      isHalfYear
      isOneYear
      isPeriodicInspection
      isBusinessInspection
      isInternalUse
      hasPhoto
      rowSpan
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInspectionModelItemChild = /* GraphQL */ `
  mutation DeleteInspectionModelItemChild(
    $input: DeleteInspectionModelItemChildInput!
    $condition: ModelInspectionModelItemChildConditionInput
  ) {
    deleteInspectionModelItemChild(input: $input, condition: $condition) {
      id
      inspectionModelItemID
      modelNumber
      itemModelNumber
      itemChildNumber
      name
      title
      unit
      judgement
      resultType
      isHalfYear
      isOneYear
      isPeriodicInspection
      isBusinessInspection
      isInternalUse
      hasPhoto
      rowSpan
      isSpecialReport
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSystemConfiguration = /* GraphQL */ `
  mutation CreateSystemConfiguration(
    $input: CreateSystemConfigurationInput!
    $condition: ModelSystemConfigurationConditionInput
  ) {
    createSystemConfiguration(input: $input, condition: $condition) {
      id
      name
      systemVersion
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSystemConfiguration = /* GraphQL */ `
  mutation UpdateSystemConfiguration(
    $input: UpdateSystemConfigurationInput!
    $condition: ModelSystemConfigurationConditionInput
  ) {
    updateSystemConfiguration(input: $input, condition: $condition) {
      id
      name
      systemVersion
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSystemConfiguration = /* GraphQL */ `
  mutation DeleteSystemConfiguration(
    $input: DeleteSystemConfigurationInput!
    $condition: ModelSystemConfigurationConditionInput
  ) {
    deleteSystemConfiguration(input: $input, condition: $condition) {
      id
      name
      systemVersion
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBoltInspection = /* GraphQL */ `
  mutation CreateBoltInspection(
    $input: CreateBoltInspectionInput!
    $condition: ModelBoltInspectionConditionInput
  ) {
    createBoltInspection(input: $input, condition: $condition) {
      id
      boltID
      bolt {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      date
      inspectionModelItemId
      itemId
      startNumber
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBoltInspection = /* GraphQL */ `
  mutation UpdateBoltInspection(
    $input: UpdateBoltInspectionInput!
    $condition: ModelBoltInspectionConditionInput
  ) {
    updateBoltInspection(input: $input, condition: $condition) {
      id
      boltID
      bolt {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      date
      inspectionModelItemId
      itemId
      startNumber
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBoltInspection = /* GraphQL */ `
  mutation DeleteBoltInspection(
    $input: DeleteBoltInspectionInput!
    $condition: ModelBoltInspectionConditionInput
  ) {
    deleteBoltInspection(input: $input, condition: $condition) {
      id
      boltID
      bolt {
        id
        windTurbineID
        number
        quantity
        torque
        confirmation
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      date
      inspectionModelItemId
      itemId
      startNumber
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBolt = /* GraphQL */ `
  mutation CreateBolt(
    $input: CreateBoltInput!
    $condition: ModelBoltConditionInput
  ) {
    createBolt(input: $input, condition: $condition) {
      id
      windTurbineID
      number
      quantity
      torque
      confirmation
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBolt = /* GraphQL */ `
  mutation UpdateBolt(
    $input: UpdateBoltInput!
    $condition: ModelBoltConditionInput
  ) {
    updateBolt(input: $input, condition: $condition) {
      id
      windTurbineID
      number
      quantity
      torque
      confirmation
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBolt = /* GraphQL */ `
  mutation DeleteBolt(
    $input: DeleteBoltInput!
    $condition: ModelBoltConditionInput
  ) {
    deleteBolt(input: $input, condition: $condition) {
      id
      windTurbineID
      number
      quantity
      torque
      confirmation
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCarryOnTool = /* GraphQL */ `
  mutation CreateCarryOnTool(
    $input: CreateCarryOnToolInput!
    $condition: ModelCarryOnToolConditionInput
  ) {
    createCarryOnTool(input: $input, condition: $condition) {
      id
      turbineInspectionID
      dateStart
      name
      quantity
      dateEnd
      technician
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCarryOnTool = /* GraphQL */ `
  mutation UpdateCarryOnTool(
    $input: UpdateCarryOnToolInput!
    $condition: ModelCarryOnToolConditionInput
  ) {
    updateCarryOnTool(input: $input, condition: $condition) {
      id
      turbineInspectionID
      dateStart
      name
      quantity
      dateEnd
      technician
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCarryOnTool = /* GraphQL */ `
  mutation DeleteCarryOnTool(
    $input: DeleteCarryOnToolInput!
    $condition: ModelCarryOnToolConditionInput
  ) {
    deleteCarryOnTool(input: $input, condition: $condition) {
      id
      turbineInspectionID
      dateStart
      name
      quantity
      dateEnd
      technician
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUsedTools = /* GraphQL */ `
  mutation CreateUsedTools(
    $input: CreateUsedToolsInput!
    $condition: ModelUsedToolsConditionInput
  ) {
    createUsedTools(input: $input, condition: $condition) {
      id
      inspectionID
      calibrationID
      calibration {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbines
      dateStart
      dateEnd
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUsedTools = /* GraphQL */ `
  mutation UpdateUsedTools(
    $input: UpdateUsedToolsInput!
    $condition: ModelUsedToolsConditionInput
  ) {
    updateUsedTools(input: $input, condition: $condition) {
      id
      inspectionID
      calibrationID
      calibration {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbines
      dateStart
      dateEnd
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUsedTools = /* GraphQL */ `
  mutation DeleteUsedTools(
    $input: DeleteUsedToolsInput!
    $condition: ModelUsedToolsConditionInput
  ) {
    deleteUsedTools(input: $input, condition: $condition) {
      id
      inspectionID
      calibrationID
      calibration {
        id
        managementNumber
        toolID
        tool {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        makerID
        maker {
          id
          name
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        modelNumber
        serialNumber
        expirationDate
        expirationObs
        certificateS3Path
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      turbines
      dateStart
      dateEnd
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCalibration = /* GraphQL */ `
  mutation CreateCalibration(
    $input: CreateCalibrationInput!
    $condition: ModelCalibrationConditionInput
  ) {
    createCalibration(input: $input, condition: $condition) {
      id
      managementNumber
      toolID
      tool {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      makerID
      maker {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      modelNumber
      serialNumber
      expirationDate
      expirationObs
      certificateS3Path
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCalibration = /* GraphQL */ `
  mutation UpdateCalibration(
    $input: UpdateCalibrationInput!
    $condition: ModelCalibrationConditionInput
  ) {
    updateCalibration(input: $input, condition: $condition) {
      id
      managementNumber
      toolID
      tool {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      makerID
      maker {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      modelNumber
      serialNumber
      expirationDate
      expirationObs
      certificateS3Path
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCalibration = /* GraphQL */ `
  mutation DeleteCalibration(
    $input: DeleteCalibrationInput!
    $condition: ModelCalibrationConditionInput
  ) {
    deleteCalibration(input: $input, condition: $condition) {
      id
      managementNumber
      toolID
      tool {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      makerID
      maker {
        id
        name
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      modelNumber
      serialNumber
      expirationDate
      expirationObs
      certificateS3Path
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTool = /* GraphQL */ `
  mutation CreateTool(
    $input: CreateToolInput!
    $condition: ModelToolConditionInput
  ) {
    createTool(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTool = /* GraphQL */ `
  mutation UpdateTool(
    $input: UpdateToolInput!
    $condition: ModelToolConditionInput
  ) {
    updateTool(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTool = /* GraphQL */ `
  mutation DeleteTool(
    $input: DeleteToolInput!
    $condition: ModelToolConditionInput
  ) {
    deleteTool(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMaker = /* GraphQL */ `
  mutation CreateMaker(
    $input: CreateMakerInput!
    $condition: ModelMakerConditionInput
  ) {
    createMaker(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMaker = /* GraphQL */ `
  mutation UpdateMaker(
    $input: UpdateMakerInput!
    $condition: ModelMakerConditionInput
  ) {
    updateMaker(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMaker = /* GraphQL */ `
  mutation DeleteMaker(
    $input: DeleteMakerInput!
    $condition: ModelMakerConditionInput
  ) {
    deleteMaker(input: $input, condition: $condition) {
      id
      name
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
