import { Table } from "src/components/tables/Table.react";

/**
 * The PrintHeader component
 *
 * @author Thais Watanabe
 */
const PrintHeader = (props) => {
  const { inspectionModel, inspection, turbineInspection } = props;
  return (
    <Table className="hidden print:table print:mb-2">
      <thead>
        <tr>
          <th
            rowSpan={2}
            colSpan={2}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            {inspectionModel}
          </th>
          <th
            rowSpan={2}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            {inspection.windFarm.name}
          </th>
          <th
            rowSpan={2}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            {inspection.name}
          </th>
          <th
            colSpan={2}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            号機
          </th>
          <th
            colSpan={3}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            {turbineInspection.windTurbine.tower}号機
          </th>
        </tr>
        <tr className="hidden print:table-row">
          <th
            colSpan={2}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            点検責任者
          </th>
          <th
            colSpan={3}
            className="p-0.5 z-20 overflow-y-auto h-5 top-0 border border-black text-center bg-gray-300 sticky"
          >
            {inspection.responsible}
          </th>
        </tr>
      </thead>
    </Table>
  );
};

export default PrintHeader;
