import { Auth, API } from "aws-amplify";

/**
 * listInGroup
 * @param {*} group
 */
export const listInGroup = async (group, nextToken) => {
  let apiName = "AdminQueries";
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: group,
      limit: 60,
      token: nextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  nextToken = NextToken;

  return rest.Users;
};

/**
 * deleteUser
 * @param {*} selectedUser
 */
export const remove = async (selectedUser) => {
  let apiName = "AdminQueries";
  let path = "/deleteUser";
  let myInit = {
    body: {
      username: selectedUser.Username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

/**
 * enableUser
 * @param {*} user
 */
export const toggleStatus = async (user) => {
  let apiName = "AdminQueries";
  let path = user.Enabled ? "/disableUser" : "/enableUser";
  let myInit = {
    body: {
      username: user.Username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

/**
 * removeFromGroup
 * @param {*} username
 * @param {*} selectedUser
 * @returns
 */
export const removeFromGroup = async (username, selectedUser) => {
  let apiName = "AdminQueries";
  let path = "/removeUserFromGroup";
  let myInit = {
    body: {
      username: username,
      groupname: selectedUser.Group,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

/**
 * addToGroup
 * @param {*} username
 * @param {*} group
 * @returns
 */
export const addToGroup = async (username, group) => {
  let apiName = "AdminQueries";
  let path = "/addUserToGroup";
  let myInit = {
    body: {
      username: username,
      groupname: group,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

/**
 * create
 * @param {*} username 
 * @param {*} email 
 * @returns 
 */
export const create = async (username, email) => {
  let apiName = "AdminQueries";
  let path = "/createUser";
  let myInit = {
    body: {
      username,
      email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};
